
//* Components */
import { defineComponent } from "vue";
//* Packages */
import http from "../../../utils/http-common";
import { toast } from 'vue3-toastify';
import ISetting from '@/interfaces/ISetting';
import IBoat from '@/interfaces/IBoat';

//* Models */

export default defineComponent({
    name: "CapacityDropdown",
    components: {},
    data() {
        return {
            selectedOption: 0,
            showOptions: false as boolean,
            options: [] as Array<number>,
            priceSettings: [] as ISetting[],
            boats: [] as IBoat[],
        };
    },
    props: {
        category: String,
        onlyDropDown: Boolean,
        zIndex: Number,
        backgroundColor: {
            type: String,
            default: "",
        },
    },
    methods: {
        toggleDropdown() {
            this.showOptions = !this.showOptions;
        },
        selectOption(option: number) {
            this.selectedOption = option;
            this.$emit("selected", option);
        },
        getPrices(): void {
            http.get(`person-price-settings`).then((res) => {
                this.priceSettings = res.data;
            })
        },
        handleClickOutside(event: Event) {
            if (!this.$el.contains(event.target)) {
                this.showOptions = false;
            }
        },
        formattedPrice(index: number): string {
            const setting = this.priceSettings.find((setting: ISetting) => setting.key === 'PERSON_PRICE_SETTINGS_' + index)
            if(setting && isNaN(parseFloat(String(setting.value)))) {
                return '0.00'
            }

            let price = parseFloat(String(setting?.value));
            let symbols = [
                '€',
                '$'
            ]

            const item = localStorage.getItem('currency_symbol');
            if (item) {
                if (symbols.includes(item)) {
                    return `${item}${price?.toFixed(2)}`;
                } else {
                    return `${price?.toFixed(2)} ${item}`;
                }
            }
            return `${price}`;
        },
        getBoats(): void {
            http.get(`boats`, {params: { active: true, pagination: false }}).then((res) => {
                this.boats = res.data;
                // this.distinctCategories().forEach((obj) => {
                //     console.log(this.parseCategory(obj))
                // })
            })
        },
        parseCategory(option: string): string {
            const result = option.match(/(.*?),/);
            result
                ? (option = result[1].replace(/\s*boat\s*/, ""))
                : (option = "");
            return result ? result[1].replace(/\s*boat\s*/, "") : "";
        },
        getCapacity(): void {
            let category = 'XL'

            http.get(`get_boat_capacity/${category}`).then((res) => {
                for (let i = 2; i < res.data.capacity + 1; i++) {
                    this.options.push(i);
                }
            })
                .catch(() => {
                    toast(this.$t('ERROR_SOMETHING_WENT_WRONG'), {
                        type: 'error',
                        position: 'top-right',
                        dangerouslyHTMLString: true,
                        autoClose: 3000
                    })
                });
        },

        distinctCategories() {
            const distinctBoats: { [key: string]: string } = {};

            this.boats.forEach((boat) => {
                if (!distinctBoats[boat.category]) {
                    distinctBoats[boat.category] = `${boat.category} boat, max ${boat.capacity} guests`;
                }
            });

            return Object.values(distinctBoats);
        },
    },

    mounted() {
        document.addEventListener("click", this.handleClickOutside);
        this.getPrices();
        this.getBoats();
        this.getCapacity();

    },
});
