<template>
  <div class="app">
  <router-view/>
  </div>
</template>

<style>

@import url('@/css/global.css');
@import url('@/css/font.css');
@import url('@/css/color.css');
@import url('@/css/animations.css');
@import url('@/css/modal.css');
@import url('@/css/scroll-bar.css');
@import url('@/css/dropdown.css');
@import url('@/css/tables.css');
@import url('@/css/media_queries.css');
@import url('@/css/shopping_cart.css');
@import url('@/css/forms.css');
@import url('@/css/mobile.css');

.app {
  height: 100svh;
  width: 100vw;
}
</style>
